
export let Selects = (function() {


	function open(event) {


		let select = event.target.closest('[data-select]');
		let dropDown = event.target.closest('[data-select-drop]');
		let dropDownItem = event.target.closest('[data-select-drop] p');
		let selects = document.querySelectorAll('[data-select]');
		

		if (dropDownItem) {
			let title = event.target.closest('[data-select]').querySelector('[data-select-caption]');
			let input = event.target.closest('[data-select]').querySelector('input');


			title.innerHTML = dropDownItem.textContent;
			input.value = dropDownItem.textContent;

			select.classList.add('is-filled');

			let allItems = document.querySelectorAll('.select_drop__item'),
				 activeItem = [].find.call(allItems, item => item.classList.contains('on-click'))
			
			if (activeItem) {

				activeItem.classList.remove('on-click');

			}
			dropDownItem.classList.add('on-click');

			input.dispatchEvent( new Event("change") );

			select.classList.toggle('is-open');
		}

		if (dropDown) {
			return;
		}
		if (!select || !select.classList.contains('is-open')) {

			allRemove(selects);

		}
		if (select) {

			select.classList.toggle('is-open');

		} else {

			allRemove(selects);

		}

	};

	function allRemove(selects) {
		[].forEach.call(selects, function(select) {
			select.classList.remove('is-open');
		});
	};

	function bindEvents() {
		document.addEventListener('click', open);
	}

	function init() {
		bindEvents();
	}
	return {
		init: init
	}

})();