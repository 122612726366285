export let staffArr = [{
    shortName: 'Александр',
    name: 'Александр Сергеевич Баженов1',
    exp: 'Опыт работы в продажах: 7 лет',
    info: 'Работая в Autocom, успешно помог перевести больше 120 автомобилей',
    img: '/assets/img/staff1.jpg'
  },
  {
    shortName: 'Евгений',
    name: 'Александр Сергеевич Баженов2',
    exp: 'Опыт работы в продажах: 7 лет',
    info: 'Работая в Autocom, успешно помог перевести больше 120 автомобилей',
    img: '/assets/img/staff1.jpg'
  },
  {
    shortName: 'Владимир',
    name: 'Александр Сергеевич Баженов3',
    exp: 'Опыт работы в продажах: 7 лет',
    info: 'Работая в Autocom, успешно помог перевести больше 120 автомобилей',
    img: '/assets/img/staff1.jpg'
  },
  {
    shortName: 'Алексей',
    name: 'Александр Сергеевич Баженов4',
    exp: 'Опыт работы в продажах: 7 лет',
    info: 'Работая в Autocom, успешно помог перевести больше 120 автомобилей',
    img: '/assets/img/staff1.jpg'
  },
  {
    shortName: 'Михаил',
    name: 'Александр Сергеевич Баженов5',
    exp: 'Опыт работы в продажах: 7 лет',
    info: 'Работая в Autocom, успешно помог перевести больше 120 автомобилей',
    img: '/assets/img/staff1.jpg'
  },
  {
    shortName: 'Александр',
    name: 'Александр Сергеевич Баженов6',
    exp: 'Опыт работы в продажах: 7 лет',
    info: 'Работая в Autocom, успешно помог перевести больше 120 автомобилей',
    img: '/assets/img/staff1.jpg'
  },
  {
    shortName: 'Владислав',
    name: 'Александр Сергеевич Баженов7',
    exp: 'Опыт работы в продажах: 7 лет',
    info: 'Работая в Autocom, успешно помог перевести больше 120 автомобилей',
    img: '/assets/img/staff1.jpg'
  },
  {
    shortName: 'Наш офис',
    name: 'Офис Autocom',
    exp: 'Нижнепортовая 1, офис 130/1',
    info: 'Ждём вас в гости с 10:00 до 19:00, с понедельника по пятницу',
    img: '/assets/img/office.jpg'
  },
];


 