import { Utils } from './utils';
import { ModalDispatcher } from './components/modalDispatcher';
import { TabsDispatcher } from './components/tabsDispatcher';
import { Selects } from './components/selects';
import { DropDown } from './components/dropDown';
import { staffArr } from './components/staff';
import anime from 'animejs/lib/anime.es.js';
import SimpleBar from 'simplebar';


import { Swiper, Navigation, Scrollbar, Mousewheel, Keyboard, Virtual, EffectFade, Pagination } from 'swiper/dist/js/swiper.esm.js';
Swiper.use([Navigation, Scrollbar, Mousewheel, Keyboard, Virtual, EffectFade, Pagination]);


document.addEventListener('DOMContentLoaded', () => {

	let anchors = document.querySelectorAll('.anchor');

	[].forEach.call(anchors, anchor => {
		anchor.addEventListener('click', e => {
			e.preventDefault();
			const category = anchor.getAttribute('href').replace('/', '');
			const pos = document.querySelector(`[data-category="${category}"]`).offsetTop;
			window.scrollTo(0, pos - 130);

			// history.pushState({}, null, anchor.getAttribute('href'));

		});
	});


	let WidgetDispatcher = (function() {

		let widget, widgetItem, widgetBtn, closewidget, hidewidget, overlaywidget, visiblewidget, choiseButton;

		function open() {

			if (widget.classList.contains('is-open')) {
				widget.classList.remove('is-open');
			} else {
				widget.classList.add('is-open');
			}

		}

		function close() {
			widget.classList.remove('is-open');
		}

		function hide() {
			widget.classList.add('is-hidden');
			widget.classList.remove('is-open');

			choiseButton.classList.add('is-move');
		}

		function visible() {
			widget.classList.remove('is-hidden');
			choiseButton.classList.remove('is-move');
		}

		function overlay() {
			widget.classList.remove('is-open');
		}

		function cacheDom() {
			widget = document.querySelector('.widget');
			widgetItem = document.querySelector('.widget_item');
			widgetBtn = document.querySelector('.widget_btn');
			closewidget = document.querySelector('.close_widget');
			hidewidget = document.querySelector('.hide_widget');
			visiblewidget = document.querySelector('.widget_visible');
			overlaywidget = document.querySelector('.widget_overlay');
			choiseButton = document.querySelector('.button_modal_choise');
		}

		function bindEvents() {

			widgetBtn.addEventListener('click', open);
			closewidget.addEventListener('click', close);
			widgetItem.addEventListener('click', close);
			hidewidget.addEventListener('click', hide);
			overlaywidget.addEventListener('click', overlay);
			visiblewidget.addEventListener('click', visible);

		}

		function init() {
			cacheDom();
			bindEvents();
		}
		return {
			init: init
		}

	})();


	let FormAuto = (function() {
		let buttons = document.querySelectorAll('.slide_auto__button');

		function description() {

			let slide = event.target.closest('.swiper-slide'),
				 model = slide.querySelector('[data-linked-auto-model]').textContent,
				 year = slide.querySelector('[data-linked-auto-year]').textContent,
				 value1 = slide.querySelector('[data-linked-auto-value1]').textContent,
				 value2 = slide.querySelector('[data-linked-auto-value2]').textContent,
				 value3 = slide.querySelector('[data-linked-auto-value3]').textContent,
				 img = slide.querySelector('.slide_auto__img img').getAttribute('src');

			let formAuto = document.querySelector('[data-modal="form_auto"]');

			formAuto.querySelector('[data-auto-model]').innerHTML = model;
			formAuto.querySelector('[data-auto-year]').innerHTML = year;
			formAuto.querySelector('[data-auto-value1]').innerHTML = value1;
			formAuto.querySelector('[data-auto-value2]').innerHTML = value2;
			formAuto.querySelector('[data-auto-value3]').innerHTML = value3;
			formAuto.querySelector('.form_auto_block__img img').setAttribute('src', img);


			document.querySelector('input[name="model"]').value = model;
			document.querySelector('input[name="year"]').value = year;
			document.querySelector('input[name="value1"]').value = value1;
			document.querySelector('input[name="value2"]').value = value2;
			document.querySelector('input[name="value3"]').value = value3;
		}


		function bindEvents() {
			
			[].forEach.call(buttons, function(el) {
				el.addEventListener('mousedown', description);
			});
			
		}

		function init() {
			bindEvents();
		}
		return {
			init: init
		}

	})();
	
	if (document.querySelector('.button_calc')) {
		document.querySelector('.button_calc').addEventListener('click', function() {
			document.querySelector('.calc_page').classList.add('up-load');
			setTimeout(() => {	
				document.querySelector('.calc_page').classList.remove('up-load');
				document.querySelector('.calc_page').classList.add('info-loaded');
			}, 2000)
		});
	}
	

	// sliders

	let sliderFullGallery = new Swiper('.slider_gallery', {
		slidesPerView: 1,
		centeredSlides: true,
		speed: 700,
		spaceBetween: 20,
		watchOverflow: true,
		virtual: {
			cache: false,
			renderSlide: function(slide) {
				return '<div class="swiper-slide"><img src="' + slide + '"/></div>'
			}
		},
		mousewheelControl: true,
		freeModeMinimumVelocity: 0.02,
		mousewheelReleaseOnEdges: false,
		mousewheel: {
			invert: true,
			enabled: true,
			forceToAxis: true
		},
		navigation: {
				nextEl: '.slider_gallery_button--next',
				prevEl: '.slider_gallery_button--prev',
			},
		scrollbar: {
			el: '.section_slider_scrollbar',
			draggable: true,
		},
		keyboard: {
			enabled: true,
			onlyInViewport: false,
		},
	});

	

	document.addEventListener('mousedown', function(event) {
		var el = event.target.closest('[data-gallery-slides]');

		if (!el) return;
		var slides = JSON.parse(el.dataset.gallerySlides);

		if (!slides) {
			return
		}

		sliderFullGallery.virtual.slides = [];
		sliderFullGallery.update();
		sliderFullGallery.virtual.slides = slides
		sliderFullGallery.slideTo(0, 0);
		sliderFullGallery.update();
		sliderFullGallery.scrollbar.updateSize();
		if (event.target.closest('[data-id]')) {
			sliderFullGallery.slideTo(+event.target.closest('[data-id]').dataset.id, 0);
		}

	});

	let swiperStaff = new Swiper('.slider_staff', {
		speed: 600,
		effect: 'fade',
		navigation: {
			nextEl: '.slider_staff__button--next',
			prevEl: '.slider_staff__button--prev',
		},
		pagination: {
			el: '.swiper-pagination',
			clickable: true,
			type: 'bullets',
			bulletClass: 'slider_staff_pagination_item',
			renderBullet: function(index, className) {
				return `<div class="${className}">
					<div class="slider_staff_pagination_item__img">
						<img src="${staffArr[index].img}">
					</div>
					<p class="slider_staff_pagination_item__name">${staffArr[index].shortName}</p>
				</div>`;
			}
		},
		on: {
			init: function() {
				let index = this.activeIndex;
				document.querySelector('.staff_description__name').textContent = staffArr[index].name;
				document.querySelector('.staff_description__exp').textContent = staffArr[index].exp;
				document.querySelector('.staff_description__lower').textContent = staffArr[index].info;
			},

			slideChange: function() {
				let index = this.activeIndex;
				document.querySelector('.staff_description__name').textContent = staffArr[index].name;
				document.querySelector('.staff_description__exp').textContent = staffArr[index].exp;
				document.querySelector('.staff_description__lower').textContent = staffArr[index].info;
			},
		}
	});

	new Swiper('.slider_auto', {
		slidesPerView: 4,
		speed: 700,
		spaceBetween: 20,
		watchOverflow: true,
		mousewheelControl: true,
		freeModeMinimumVelocity: 0.02,
		mousewheelReleaseOnEdges: false,
		mousewheel: {
			invert: true,
			enabled: true,
			forceToAxis: true
		},
		navigation: {
				nextEl: '.slider_auto__button--next',
				prevEl: '.slider_auto__button--prev',
		},
		keyboard: {
			enabled: true,
			onlyInViewport: false,
		},
		scrollbar: {
			el: '.slider_auto__scrollbar',
			draggable: true,
		},
		breakpoints: {
			500: {
				slidesPerView: 1,
				spaceBetween: 15,
			},
		}
	});

	new Swiper('.contract_slider', {
		slidesPerView: 'auto',
		speed: 700,
		spaceBetween: 0,
		watchOverflow: true,
		mousewheelControl: true,
		freeModeMinimumVelocity: 0.02,
		mousewheelReleaseOnEdges: false,
		touchStartPreventDefault: false,
		mousewheel: {
			invert: true,
			enabled: true,
			forceToAxis: true
		},
		navigation: {
				nextEl: '.contract_slider__button--next',
				prevEl: '.contract_slider__button--prev',
		},
		scrollbar: {
			el: '.contract_slider__scrollbar',
			draggable: true,
		},
		keyboard: {
			enabled: true,
			onlyInViewport: false,
		},
		breakpoints: {
			500: {
				spaceBetween: 10,
			},
		}
	});
	
	// sliders end

	// checkHeader

	function checkHeader() {

		let scrollPosition = Math.round(window.scrollY);
		if (scrollPosition > 50){
			document.body.classList.add('sticky');
		} else {
			document.body.classList.remove('sticky');
		}
	}

	window.addEventListener('scroll', Utils.throttle(checkHeader, 5));

	// end checkHeader


	// load page
	
	document.body.classList.add('is-loaded');
	
	// end load page


	// simpleBar

	let simpleBar = document.querySelectorAll('[data-simlebar]');
	simpleBar.forEach(el => {
		new SimpleBar(el);
	});

	// end simpleBar


	// input filled
	
	let inputsForm = document.querySelectorAll('[page-input]');
	[].forEach.call(inputsForm, function(input) {


		input.addEventListener('input', function(event) {

			setTimeout(() => {	
				if(input.value) {
					input.classList.add('is-filled');
				} else {
					input.classList.remove('is-filled');
				}
			})

		});
	});

	// end input filled


	// input masks

	let dynamicInputs = document.querySelectorAll('[name="dynamic"]');
	let dynamicOptions = {
		mask: [
		{
			mask: '+{7} (000) 000-00-00',
		},
		{
			mask: /^\S*@?\S*$/,
		}
		]

	};

	[].forEach.call(dynamicInputs, function (dynamicInput) {
		let dynamicMask = IMask(dynamicInput, dynamicOptions);
	});

	var phoneElem = document.querySelectorAll('[name="phone"]'),
	phoneMasks = [];
	[].forEach.call(phoneElem, function(el, i) {
		phoneMasks[i] = new IMask(el, {
			mask: '+{7} (0',
			placeholderChar: ''
		});
		el.addEventListener('focus', function() {
			phoneMasks[i].updateOptions({ lazy: false })
		})
		phoneMasks[i].on('accept', function() {
			var mask = (phoneMasks[i]._unmaskedValue === '7') ? '+{7} (0' : '+{7} (000) 000-00-00',
			placeholderChar = (phoneMasks[i]._unmaskedValue === '7') ? '' : '_';
			phoneMasks[i].updateOptions({ mask: mask, placeholderChar: placeholderChar })
		})
		el.addEventListener('blur', function() {
			if (phoneMasks[i]._unmaskedValue.length < 11) {
				el.value = '';
				phoneMasks[i].updateOptions({ lazy: true })
				phoneMasks[i].value = '';
			}

		})

	});

	//end input masks
	

	TabsDispatcher.init();
	Selects.init();
	DropDown.init();
	ModalDispatcher.init();
	FormAuto.init();
	WidgetDispatcher.init();

})