
export let DropDown = (function() {


	function open(event) {


		let dropDownBlock = event.target.closest('[data-dropdown-block]'),
			 dropDownContent = event.target.closest('[data-dropdown-content]'),
			 dropDownItem = event.target.closest('[data-dropdown-item]'),
			 dropDownBlocks = document.querySelectorAll('[data-dropdown-block]');
		
		if (dropDownItem) {
			select.classList.toggle('is-open');
		}

		if (event.target.closest('[data-drop-close]')) {
			dropDownBlock.classList.toggle('is-open');
		}


		if (dropDownContent) {
			return;
		}

		if (!dropDownBlock || !dropDownBlock.classList.contains('is-open')) {

			allRemove(dropDownBlocks);

		}
		
		if (dropDownBlock) {

			dropDownBlock.classList.toggle('is-open');

		} else {

			allRemove(dropDownBlocks);

		}


	};

	function allRemove(dropDownBlocks) {
		[].forEach.call(dropDownBlocks, function(el) {
			el.classList.remove('is-open');
		});
	};

	function bindEvents() {
		document.addEventListener('mousedown', open);
	}

	function init() {
		bindEvents();
	}
	return {
		init: init
	}

})();