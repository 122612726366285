import { Modal } from './modal';

export let ModalDispatcher = (function () {

	let appRoot,
	commonModalOverlay,
	commonModalOverlayRect,
	commonCloseButton,
	activeModal =  null,
	modalsList = {};

	function checkIfModalInList(modalName) {
		return (typeof modalsList[modalName] !== 'undefined')
	}

	function createModal(modal) {
		let modalName = modal.dataset.modal;
		if (!checkIfModalInList(modalName)) {
			modalsList[modalName] = new Modal(modal);
		}


	}

	function fillList() {
		let modals = document.querySelectorAll('[data-modal]');
		[].forEach.call(modals, createModal);
	}


	function showModal(modal) {
		if (activeModal) {
			if (activeModal === modalsList[modal.parentModal]) {
				modalsList[modal.parentModal].isOnActiveChild = true
			}
			else {
				closeModal(activeModal)
			}
		}
		modal.isActive = true
		activeModal = modal;
		handleOverlay();
	}

	function closeModal(modal) {

		if (activeModal.parentModal && !(activeModal !== modal && modalsList[activeModal.parentModal] === modal)) {
			modalsList[activeModal.parentModal].isOnActiveChild = false;
			activeModal.isActive = false;
			activeModal = modalsList[activeModal.parentModal]
		}
		else { 
			closeAll()
		}


		handleOverlay();
	}

	function handleClosing(modal = null) {
		if (modal) {
			closeModal(modal);
		}
		else {
			closeAll();
		}
	}

	function closeAll(event) {
		if (!event || event.which === 1) {
			if (activeModal) {
				activeModal.isActive = false;
				if (modalsList[activeModal.parentModal]) {
					modalsList[activeModal.parentModal].isActive = false;
					modalsList[activeModal.parentModal].isOnActiveChild = false;
				}

				activeModal = null;
				handleOverlay();
			}

		}


	}

	function handleOverlay() {
		if (!commonModalOverlay) {
			return 
		}
		if (activeModal && activeModal.needOverlay) {
			commonModalOverlay.classList.add('is-active');
			if (commonCloseButton) {
				commonCloseButton.style.willChange = 'transform';
			}
		}
		else {
			commonModalOverlay.classList.remove('is-active');
			if (commonCloseButton) {
				commonCloseButton.style.willChange = '';
			}
		}
	}

	function moveCloseButton(event) {
		let x = event.pageX,
		y = event.pageY,
		modalOffsetX = commonModalOverlayRect.left,
		modalOffsetY = commonModalOverlayRect.top,
		toX = x - modalOffsetX - commonCloseButton.offsetWidth / 2,
		toY = y - modalOffsetY - commonCloseButton.offsetHeight / 2;

		commonCloseButton.style.transform =
		'translate3d(' + toX + 'px, ' + toY + 'px, 0)';
	}

	function hideCloseButton() {
		commonCloseButton.style.display = 'none';
	}
	function showCloseButton() {
		commonCloseButton.style.display = '';
	}

	function bindEvents() {
		document.addEventListener('mousedown', function (event) {
			if (event.which === 1) {
				let toggle = event.target.closest('[data-linked-modal]'),
				modal = {};
				if (!!toggle && !!modalsList[toggle.dataset.linkedModal]) {
					toggle.classList.add('is-clicked');
					modalsList[toggle.dataset.linkedModal].clickedToggle = toggle;
					showModal(modalsList[toggle.dataset.linkedModal]);
					event.stopPropagation();
					event.preventDefault();
				}

			}
		});

		document.addEventListener('mousedown', function (event) {
			if (event.which === 1) {
				let closeButton = event.target.closest('[data-modal-close]');
				if (!!closeButton) {
					var modal = modalsList[closeButton.closest('[data-modal]').dataset.modal];
					handleClosing(modal);
				}
			}
		})
		document.addEventListener('keyup', function (event) {
			event = event || window.event;
			if (event.keyCode == 27) {
				handleClosing(activeModal);
			}
		});

		if (commonModalOverlay) {
			commonModalOverlay.addEventListener('mousedown', closeAll);
			if (commonCloseButton) {
				commonModalOverlayRect = commonModalOverlay.getBoundingClientRect();
				commonModalOverlay.addEventListener('mousemove', Utils.throttle(moveCloseButton, 10));
				commonModalOverlay.addEventListener('mouseleave', hideCloseButton);
				commonModalOverlay.addEventListener('mouseenter', showCloseButton);
			}
		}
	}

	function init() {
		commonModalOverlay = document.getElementById('commonModalOverlay');
		commonCloseButton = document.getElementById('commonCloseButton');
		fillList();
		bindEvents();

	}
	return {
		init: init    
	}

})();